.f-container{
    justify-content: space-between;
    gap: 4rem;
}

.f-left{
    gap: 1rem
}

.f-menu{
    gap: 1.5rem;
    margin-top: 1.5rem;
    font-weight: 500;
    display: flex;
}

@media(max-width: 780px){
    .f-container{
        justify-content: center;
    }
    .f-container>div{
        align-items: center !important;
        text-align: center;
    }
}