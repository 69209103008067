:root {
  --primary : #1f3e72;
  --secondary : rgba(225, 225, 225, 0.78);
  --black : #131110;
  --blue-gradient : linear-gradient(97.05deg, #4066ff 3.76%, #2949c6 100%);
  --orange-gradient : linear-gradient(270deg, #ffb978 0%, #ff922d 100%);
  --blue : #4066ff;
  --lightBlue : #eeeeff;
  --shadow : 0px 23px 21px -8px rgba(136, 160, 255, 0.25);
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
}

body{
  height: 1000px;
}

a{
  color: inherit;
  text-decoration: none;
}

.paddings {
  padding: 1.5em;
}

.innerWidth {
  width: 100%;
}

.flexCenter {
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flexColStart{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}


.button{
  font-weight: 500;
  padding: 0.6rem 1.4rem;
  color: white;
  background: var(--blue-gradient);
  border: none;
  border-radius: 4px;
  transition: all 300ms ease-in;
}

.button:hover{
  cursor: pointer;
  transform: scale(1.1);
}

.primaryText{
  color: #1f3e72;
  font-weight: bold;
  font-size: 2rem;
}

.secondaryText{
  color : rgb(140 139 139);
  font-size: 0.9rem;
}

.orangeText{
  color: orange;
  font-size: 1.5em;
  font-weight: 600;
}



/* media queries */
@media(min-width : 1280px), (min-width : 768px){
    .paddings{
      padding: 2rem;
    }
}

@media (min-width : 1000px){
  .innerWidth{
    max-width: 1350px;
    margin:auto ;
  }
}

@media(min-width : 650px){
  .paddings{
    padding: 4rem;
  }
}