.App{
    position: relative;
}

.App>:nth-child(1){
    background:var(--black) ;
    position: relative;
}


.White-gradient{
    position: absolute;
    width: 20rem;
    height: 20rem;
    top: 0%;
    left: 0%;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.223);
    filter: blur(100px);
}